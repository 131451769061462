/* eslint import/prefer-default-export:off */

export const UNSPLASH_DEFAULT_IMAGE = {
  urls: {
    raw: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3",
    full: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=85",
    regular: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=1080",
    small: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=400",
    thumb: "https://images.unsplash.com/photo-1543726969-a1da85a6d334?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxNTIxOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTI3ODY1MTF8&ixlib=rb-4.0.3&q=80&w=200",
    small_s3: "https://s3.us-west-2.amazonaws.com/images.unsplash.com/small/photo-1543726969-a1da85a6d334"
  }
};

export const COMPANIES_TABLE = "companies";
export const DEPARTMENTS_TABLE = "departments";
export const SKILLS_TABLE = "skills";
