import imagesSizes from "constants/imagesSizes";
import { Story } from "chq";

export const getMediaFromStory = (size: number, story: Story) => {
  const media = story?.media;

  if (media?.mediaType === "video") {
    if (size === imagesSizes.SHAREABLE) {
      return media.shareableUrl || media.url;
    }

    return media.url;
  }

  switch (size) {
    case imagesSizes.THUMB:
      return media?.thumbnail || media?.url;
    case imagesSizes.SMALL:
      return media?.smallUrl || media?.url;
    case imagesSizes.NORMAL:
      return media?.url;
    case imagesSizes.FULL:
    case imagesSizes.SHAREABLE:
      return media?.fullUrl || media?.url;
    default:
      return "";
  }
};

export default getMediaFromStory;
